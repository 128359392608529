.sections {
  height: 400px;
  width: 250px;
  background-color: #069;
  border: 2px dotted rgb(0, 8, 12);
  margin-bottom: 20px;
  color: #eee;
}

.plays-banner {
  padding-top: 3%;

  h1 {
    opacity: 0.8;
    font-size: 900%;
    border: 4px solid #CBA52B;
    border-radius: 6px;
    box-shadow: inset 2px 2px 10px rgba(100, 100, 100, 0.8), inset -2px -2px 10px rgba(100, 100, 100, 0.8);
    display: inline;
    padding: 0 9px 32px 3px;
    background-color: rgba(100, 100, 100, 0.2);
  }
}

.speaker-plays-thumb-logo {
  display: block;
  margin: 0 auto;
  padding: 5% 0;
  width: 45%;
}

@media screen and (max-width: 590px) {
  .plays-banner {
    h1 {
      font-size: 500%;
      padding: 0 9px 17px 3px;
    }
  }

  .speaker-plays-thumb-logo {
    padding: 19% 0;
    width: 60%;
  }
}
