html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poiret One', cursive;
  background-color: #60532B;
  position: relative;
}

h2 {
  font-size: 230%;
}

li {
  list-style: none;
}

.textCenter {
  text-align: center;
}

.fontStyle {
  color: #CBA52B;
  text-shadow: 2px 2px 1px #333;
}
