$yellow: #CBA52B;
$viewHeight: 70vh;

.sb-bg {
  background-size: cover;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-position: center center;
}

.sb-bg-1 {
  height: $viewHeight;
  background-image: url(../assets/guitars/g8s.jpg);
}

.sb-bg-2 {
  height: $viewHeight;
  background-image: url(../assets/guitars/g1s.jpg);
}

.sb-bg-3 {
  height: $viewHeight;
  background-image: url(../assets/guitars/g2s.jpg);
}

.sb-bg-4 {
  height: $viewHeight;
  background-image: url(../assets/guitars/g5s.jpg);
}

.sb-bg-5 {
  height: $viewHeight;
  background-image: url(../assets/guitars/g4s.jpg);
}

.sb-color {
  padding: 30px;
  overflow: auto;
  // background-color: #4f3b00;
  background-color: #080806;
  background-image: url(../assets/fabric.png);
  color: $yellow;
  // box-shadow: inset -2px 7px 7px rgba(225, 195, 0, 0.3), inset 2px -7px 9px rgba(225, 195, 0, 0.3);
  box-shadow: inset -2px 7px 7px rgba($yellow, 0.4), inset 2px -7px 9px rgba($yellow, 0.4);
}
