$yellow: #CBA52B;

.projectSection {
  color: $yellow;

  h2 {
    margin: 0 auto 20px;
  }

  ul {
    padding: 0 50px;

    li {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin-top: 15px;

      &:not(:last-child) {
        padding-bottom: 15px;
        border-bottom: 2px dashed $yellow;
      }

      .projectSectionSite {
        flex: 0 0 28%;
        text-align: right;
        margin-right: 15px;

        a {
          padding: 0 20px 0 0;
          color: $yellow;
          text-decoration: none;

          i {
            font-size: 140%;
            margin-right: 10px !important;
          }

          &:hover {
            color: #ccc;
            cursor: pointer;
          }
        }
      }

      .projectSectionDescription {
        flex: 1;
      }
    }
  }
}


