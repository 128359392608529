@import '../../scss/footer-fa-social';

.footerSection {
  height: 80px;
  overflow: hidden;

  ul {
    margin: -70px 30px 30px;
    float: right;
    list-style-type: none;
  }

  li {
    float: left;

    &:not(:last-child) {
      padding-right: 10px;
    }
  }

  img {
    margin: 0 auto;
    display: block;
    width: 130px;
    transition: all 0.7s ease;
    opacity: 0.8;

    &:hover {
      animation: shake 0.82s cubic-bezier(.36, .07, .19, .97) both;
      transform: scale(1.1);
    }
  }

  @keyframes shake {
    10%,
    90% {
      transform: translate3d(-1px, 0, 0) scale(1.1);
    }
    20%,
    80% {
      transform: translate3d(2px, 0, 0) scale(1.1);
    }
    30%,
    50%,
    70% {
      transform: translate3d(-4px, 0, 0) scale(1.1);
    }
    40%,
    60% {
      transform: translate3d(4px, 0, 0) scale(1.1);
    }
  }
}

@media screen and (max-width: 770px) {
  .footerSection {
    height: 160px;

    ul {
      margin: 28px auto;
      width: 210px;
      float: none;
    }
  }
}
